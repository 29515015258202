<!-- 投资者关系 -->
<template>
  <div>
    <!-- 导航栏 -->
    <navs />
    <!-- 内容 -->
    <router-view />
    <!-- 底部 -->
    <foot />
    <!-- 回到顶部 -->
    <call />
  </div>
</template>

<script>
import Navs from '@/components/index/navs.vue';
import Foot from '@/components/index/foot.vue';
import Call from '@/components/index/BackTop.vue';
export default {
  components: { Navs, Foot, Call },
  mounted() {
    // 设置项目title
    document.title = '投资者关系_天佑德青稞酒官网'
  }
}
</script>

<style lang="less" scoped>
</style>