<!-- 青稞酒酿造生态圈 -->
<template>
	<div class="naissance">
		<div class="naissance_bg"></div>
		<div class="naissance_content">
			<div class="naissance_title">
				<h2>青稞酒酿造生态圈</h2>
				<p>从田间到餐桌的全产业链品质把控</p>
			</div>
			<ul class="naissance_all">
				<li class="naissance_li">
					<div class="naissance_img" @click="goPropaganda('/company/propaganda','propaganda_1938')">
						<img src="../../static/img/naissance1.png" alt="">
						<img src="../../static/img/naissance4.png" class="naissance_icon">
					</div>
					<div class="naissance_text">
						<h3>好酒是【种】出来的</h3>
						<p>拥有德令哈等25万亩青稞种植基地，其中10万亩获得有机认证。</p>
						<p>拥有储能达7.4万吨的青稞大粮仓，可满足40万人食用一年。</p>
						<p>唯一将『纯净无污染』写入国家地理保护标志执行标准的白酒企业。</p>
					</div>
				</li>
				<li class="naissance_li">
					<div class="naissance_img" @click="goPropaganda('/company/propaganda','propaganda_1939')">
						<img src="../../static/img/naissance2.png" alt="">
						<img src="../../static/img/naissance5.png" class="naissance_icon">
					</div>
					<div class="naissance_text">
						<h3>好酒是【酿】出来的</h3>
						<p>一年四季不间断酿酒，是中国唯一能够酿造夏季酒体的白酒上市公司。</p>
						<p>无断代传承的『青海青稞酒传统酿造技艺：清蒸清烧四次清』被列入国家非物质文化遗产名录。</p>
						<p>拥有国家级白酒评委5人，一级品酒师20余人，国家级非遗酿造工艺团队150余人。</p>
					</div>
				</li>
				<li class="naissance_li">
					<div class="naissance_img" @click="goPropaganda('/company/propaganda','propaganda_1940')">
						<img src="../../static/img/naissance3.png" alt="">
						<img src="../../static/img/naissance6.png" class="naissance_icon">
					</div>
					<div class="naissance_text">
						<h3>好酒是【存】出来的</h3>
						<p>陈储超过5万吨的优质青稞原酒。</p>
						<p>独创四级陈藏法：罐储陈化、陶坛老熟、酒海生香、瓶储融合。</p>
						<p>拥有35个明清时期的老酒海，是还在使用的文物。</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			//点击导航栏跳转到指定位置
			goPropaganda(path, id) {
				var path = path
				var Id = id;
				localStorage.setItem('toId', Id);
				this.$router.push(path);
			}
		}
	}
</script>

<style lang="less" scoped>
	.naissance {
		position: relative;
		padding: 96px 0 72px;

		@media screen and (max-width:1199px) {
			padding: 50px 0 30px;
		}
	}

	.naissance_bg {
		background-color: #e1e1e1;
		width: 100%;
		height: 322px;
		position: absolute;
		bottom: 0;
		z-index: -1;

		@media screen and (max-width:1199px) {
			height: 100%;
		}
	}

	.naissance_content {
		width: 1200px;
		margin: 0 auto;
		z-index: 999;

		@media screen and (max-width:1199px) {
			width: 100%;
			padding: 0 4%;
			box-sizing: border-box;
		}
	}

	.naissance_title {
		text-align: center;

		h2 {
			font-size: 40px;

			@media screen and (max-width:767px) {
				font-size: 30px;
			}
		}

		p {
			font-size: 18px;
			margin: 24px 0 48px;

			@media screen and (max-width:767px) {
				font-size: 16px;
				margin: 25px 0 40px;
			}
		}
	}

	.naissance_all {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.naissance_li {
			width: 32%;
			background-color: #fff;

			@media screen and (max-width:1199px) {
				width: 100%;
				margin-bottom: 30px;
			}

			.naissance_img {
				width: 100%;
				position: relative;
				overflow: hidden;

				img:first-child {
					-webkit-transition: opacity .4s ease-in, -webkit-transform .6s linear;
					transition: opacity .4s ease-in, transform .6s linear;
					transform: scale(1);
				}

				@media screen and (min-width:1200px) {
					img:first-child:hover {
						transform: scale(1.1);
					}
				}

				img {
					width: 100%;
					vertical-align: bottom;
				}

				.naissance_icon {
					width: 18%;
					// width: 70px;
					// height: 70px;
					position: absolute;
					bottom: 0px;
					// right: 15px;
					right: 4%;
				}
			}
		}
	}

	.naissance_text {
		box-sizing: border-box;
		// height: 250px;
		padding: 0 24px 40px;

		@media screen and (max-width:1199px) {
			padding: 0 24px 20px;
		}

		h3 {
			font-size: 18px;
			line-height: 54px;
		}

		p {
			@media screen and (min-width:768px) {
				height: 42px;
			}
		}

		p:nth-child(3) {
			padding: 15px 0;
		}
	}
</style>
