<!-- 首页 -->
<template>
  <div class="index">
    <!-- 底部导航栏 -->
    <navs />
    <!-- 轮播图 -->
    <lun-bo :toLunBo='newData.shuffling' />
    <!-- 一瓶好酒的诞生 -->
    <Naissance />
    <!-- 酿造车间 -->
    <lun-bo-car />
    <!-- 大数据 -->
    <!-- <latest /> -->
    <!-- 新闻 活动 -->
    <news :toNews='newData.article' />
    <!-- <IndexHonor /> -->
    <!-- 底部 -->
    <foot />
    <!-- 回到顶部 -->
    <go-top />
  </div>
</template>

<script>
// 引入使用导航栏
import Navs from "@/components/index/navs.vue";
// 轮播图
import LunBo from "@/components/index/LunBoTu.vue";
// 一瓶好酒的诞生
import Naissance from "@/components/index/naissance.vue";
// 酿造车间
import LunBoCar from "@/components/index/LunBoCar.vue";
// 最新产品
import Latest from '@/components/index/Latest.vue';
// 新闻与活动
import News from "@/components/index/news.vue";
// import IndexHonor from "@/components/index/IndexHonor.vue";
// 底部
import Foot from "@/components/index/foot.vue";
//回到顶部
import GoTop from "@/components/index/BackTop.vue"
export default {
  components: {
    Navs,
    LunBo,
    Naissance,
    LunBoCar,
    Latest,
    News,
    // IndexHonor,
    Foot,
    GoTop
  },
  data() {
    return {
      newData: '',
      // 最新产品数据
      traceability: {}
    }
  },
  mounted() {
    // 设置项目title
    document.title = '首页_天佑德青稞酒_青海互助天佑德青稞酒股份有限公司'
    this.$axios.get('/qkj/v1.home/index')
      .then(response => {
        this.newData = response.data.data.data
        console.log(this.newData.shuffling);
        // 最新产品数据
        this.traceability = response.data.data.data.traceability[0]
      })
      // 错误处理
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
</style>