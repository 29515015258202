<!-- 首页导航栏 -->
<template>
	<div class="header">
		<div id="head" class="head" @mouseenter="changeBg()" @mouseleave="changeOriginal()">
			<div class="header_content">
				<div class="header_left">
					<router-link to="/index">
						<img class="head_img" :src="logo" alt="" />
					</router-link>
				</div>
				<ul class="header_center">
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/index" class="a">首页</router-link>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/company" class="a">走进天佑德</router-link>
						</div>
						<div class="header_list">
							<dl>
								<dd>
									<router-link active-class="active" to="/company/profile">公司简介 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/history">企业历程 ></router-link>
								</dd>
								<!-- <dd>
									<router-link active-class="active" to="/company/framework">品牌架构 ></router-link>
								</dd> -->
								<!-- <dd>
									<router-link active-class="active" to="/company/purpose">理念宗旨 ></router-link>
								</dd> -->
								<dd>
									<router-link active-class="active" to="/company/honor">企业荣誉 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/duty">社会责任 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/tour">青稞之旅 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/propaganda">宣传片 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/cooperation">合作Kol ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/company/culture">文化天佑德 ></router-link>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/product" class="a">品牌产品</router-link>
						</div>
						<div class="header_list header_listProduct">
							<dl>
								<dd v-for="item in goods_category" :key="item.id" class="product_dl">
									<router-link :to="'/product/list_' + item.id" v-if="item.name != '纳曲'">
										<img :src="item.logo_thumbnail" alt="">{{ item.name }} >
									</router-link>
									<a v-else href="http://nqqkj.com.cn/product/list-23.html" target="_blank">
										<img :src="item.logo_thumbnail" alt="">{{ item.name }} >
									</a>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/news" class="a">新闻资讯</router-link>
						</div>
						<div class="header_list">
							<dl>
								<dd v-for="item in toNavs.art_category" :key="item.id">
									<router-link active-class="active" :to="'/news/' + item.id">{{ item.name }} >
									</router-link>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/research" class="a">青稞研究院</router-link>
						</div>
						<div class="header_list">
							<dl>
								<dd>
									<router-link active-class="active" to="/research/healthy" class="a">健康大讲堂
									</router-link>>
								</dd>
								<dd>
									<a href="http://jz.qkj.com.cn/" target="_blank">酿酒微生物 ></a>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/service" class="a">服务中心</router-link>
						</div>
						<div class="header_list">
							<dl>
								<dd @click="goPosition('/service/serviceCon', 'mail')">
									<router-link active-class="active" to="/service/serviceCon">通讯录 ></router-link>
								</dd>
								<dd @click="goPosition('/service/serviceCon', 'aim')">
									<router-link active-class="active" to="/service/serviceCon">服务热线 ></router-link>
								</dd>
								<dd @click="goPosition('/service/serviceCon', 'sales')">
									<router-link active-class="active" to="/service/serviceCon">销售网络 ></router-link>
								</dd>
								<dd @click="goPosition('/service/serviceCon', 'recruit')">
									<router-link active-class="active" to="/service/serviceCon">人才招聘 ></router-link>
								</dd>
								<dd>
									<a href="https://pc-lilinger.002646.com" target="_blank">AI李灵儿 ></a>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/investor" class="a">投资者关系</router-link>
						</div>
						<div class="header_list">
							<dl>
								<dd>
									<router-link active-class="active" to="/investor">股票概况 ></router-link>
								</dd>
								<dd>
									<a href="http://www.szse.cn/disclosure/listed/fixed/index.html?name=%E5%A4%A9%E4%BD%91%E5%BE%B7%E9%85%92&stock=002646&r=1661157424059"
										target="_blank">定期报告 ></a>
								</dd>
								<dd>
									<router-link active-class="active" to="/investor/2">投资者教育与保护 ></router-link>
								</dd>
								<dd>
									<a href="https://ir.p5w.net/c/002646.shtml" target="_blank">投资者互动 ></a>
								</dd>
								<dd>
									<a href="https://www.investor.org.cn" target="_blank">中国投资者网 ></a>
								</dd>
							</dl>
						</div>
					</li>
					<li>
						<div class="header_li">
							<router-link active-class="active" to="/pku" class="a">天佑德×北大</router-link>
						</div>
						<!-- <div class="header_list">
							<dl>
								<dd>
									<router-link active-class="active" to="/culture/enterprise">企业文化 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/culture/brand">品牌文化 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/culture/tianniang">天酿文化 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/culture/sanctum">圣地文化 ></router-link>
								</dd>
								<dd>
									<router-link active-class="active" to="/culture/qingzang">青藏文化 ></router-link>
								</dd>
							</dl>
						</div> -->
					</li>
				</ul>
				<div class="header_right">
					<div class="header_shop" @click="shoppping($event)">
						<div class="iconfont icon-gouwuchekong header_sousuo"> <span>商城</span></div>
						<div class="header_fnt">
							<div class="header_shopping">
								<a href="https://www.zhongjiu.cn/list/2-838-0-1-1-1.htm" target="_blank"><img
										src="../../static/img/shop1.png" alt=""></a>
								<a href="https://tianyoude.tmall.com/shop/view_shop.htm" target="_blank">
									<img src="../../static/img/shop2.png" alt=""></a>
								<a href="https://mall.jd.com/index-714336.html?from=pc" target="_blank"><img
										src="../../static/img/shop3.png" alt=""></a>
							</div>
						</div>
					</div>
					<!-- 新增英文页面跳转 -->
					<div class="header_icon header_en">
						<a href="http://english.002646.com/home/index/index" target="_blank">
							<span class="iconfont icon-yingwen1"></span>
						</a>
					</div>
					<!-- 暂时去掉搜索框 安全风险 -->
					<!-- <div class="header_icon" @click="search()">
						<div class="iconfont icon-sousuotianchong header_sousuo"></div>
					</div> -->
				</div>
			</div>
			<!-- 移动端菜单 -->
			<div class="header_iphone_right">
				<span class="iconfont icon-danlieliebiao" @click="openEl"></span>
				<span class="iconfont icon-guanbi2" @click="closeEl"></span>
			</div>
			<div class="side-bar">
				<el-scrollbar style="height: 100%;">
					<el-col :span="12" ref="elCol">
						<el-menu unique-opened router @select="selectMenu" active-text-color="#000" default-active="2"
							class="el-menu-vertical-demo header_iphone_ul">
							<li class="header_iphone_english">
								<a href="http://english.002646.com/home/index/index" target="_blank">
									<span class="iconfont icon-yingwen1"></span>
								</a>
							</li>
							<el-menu-item index="/index">
								<span slot="title">首页</span>
							</el-menu-item>
							<el-submenu index="2">
								<template slot="title">
									<span>走进天佑德</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/company/profile">企业简介</el-menu-item>
									<el-menu-item index="/company/history">企业历程</el-menu-item>
									<el-menu-item index="/company/honor">企业荣誉</el-menu-item>
									<el-menu-item index="/company/duty">社会责任</el-menu-item>
									<el-menu-item index="/company/tour">青稞之旅</el-menu-item>
									<el-menu-item index="/company/propaganda">宣传片</el-menu-item>
									<el-menu-item index="/company/culture">文化天佑德</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="3">
								<template slot="title">
									<span>品牌产品</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/product">品牌产品首页</el-menu-item>
									<el-menu-item v-for="item in goods_category" :key="item.id">
										<router-link v-if="item.name != '纳曲'" :to="'/product/list_' + item.id">{{
											item.name
										}}</router-link>
										<a v-else href="http://nqqkj.com.cn/product/list-23.html" target="_blank">{{
											item.name
										}}</a>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="4">
								<template slot="title">
									<span>新闻资讯</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/news">新闻资讯首页</el-menu-item>
									<el-menu-item :index="'/news/' + item.id" v-for="item in toNavs.art_category"
										:key="item.id">{{ item.name }}</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="5">
								<template slot="title">
									<span>青稞研究院</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/research/healthy">健康大讲堂</el-menu-item>
									<el-menu-item><a href="http://jz.qkj.com.cn/" target="_blank">酿酒微生物</a>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="6">
								<template slot="title">
									<span>服务中心</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/service">服务中心首页</el-menu-item>
									<el-menu-item><a href="https://m-lilinger.002646.com/" target="_blank">AI李灵儿</a>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-submenu index="7">
								<template slot="title">
									<span>投资者关系</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/investor">股票概况</el-menu-item>
									<el-menu-item>
										<a href="http://www.szse.cn/disclosure/listed/fixed/index.html?name=%E5%A4%A9%E4%BD%91%E5%BE%B7%E9%85%92&stock=002646&r=1661157424059"
											target="_blank">定期报告</a>
									</el-menu-item>
									<el-menu-item index="/investor/2">投资者教育与保护</el-menu-item>
									<el-menu-item>
										<a href="https://ir.p5w.net/c/002646.shtml" target="_blank">投资者互动</a>
									</el-menu-item>
									<el-menu-item>
										 <a href="https://www.investor.org.cn" target="_blank">中国投资者网</a>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<el-menu-item index="/pku">
								<span slot="title">天佑德×北大</span>
							</el-menu-item>
						</el-menu>
					</el-col>
				</el-scrollbar>
			</div>
		</div>
		<!-- 搜索框 -->
		<div class="search">
			<div @click="searchOff" class="search_box"></div>
			<div class="search_content">
				<div class="search_img">
					<img src="../../static/img/tyd_logo.jpg" alt="" />
				</div>
				<div class="search_text">
					<i class="iconfont icon-sousuotianchong search_sousuo"></i>
					<input type="text" placeholder="搜索 国之德..." autocomplete="off" class="search-input"
						@input="inputInp($event)">
					<i @click="searchOff" class="iconfont icon-guanbi"></i>
				</div>
			</div>
			<div class="search_push" v-if="inputMsg.length > 0">
				<h3>快速搜索</h3>
				<ul>
					<li class="search_list" v-for="item in inputMsg" :key="item.id">
						<router-link :to="'/product/details/' + item.id" target="_blank">{{ item.post_title }}
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
	throttle
} from '@/libs/utils'
import $ from 'jquery'
export default {
	data() {
		return {
			// 页面滚动的高度
			scrollTop: 0,
			// 动态改变logo
			logo: require("../../static/img/白logo.png"),
			//是否滚动变量
			mouseIsTrue: false,
			// 商城点击状态
			shop: 0,
			// 后台请求来的数据
			toNavs: '',
			//品牌产品导航栏数据
			goods_category:[],
			//当前路由路径
			path: '',
			// 搜索内容
			inputMsg: '',
			active: false
		}
	},
	mounted() {
		// 实现悬浮显示菜单效果
		$(".header_center li").mouseenter(function () {
			$(this).find(".header_list").stop().slideDown(800);
		})
		$(".header_center li").mouseleave(function () {
			$(this).find(".header_list").stop().slideUp(600);
		})
		window.addEventListener('scroll', this.getScroll)
		//获取到当前路由的路径 判断是否是首页 如果不是 让顶部背景变成黑色
		this.path = this.$route.path;
		// 请求后台数据
		this.$axios.get('/qkj/v1.home/index')
			.then(response => {
				// 数据
				this.toNavs = response.data.data.data.subcategory
				// 循环遍历品牌产品数据 查看是否存在经典老产品
				// 如存在 建立新的数组 删除经典老产品数据 不显示在导航栏
				for (let i = 0; i < this.toNavs.goods_category.length; i++) {
					if (this.toNavs.goods_category[i].name != '经典老产品') {
						this.goods_category.push(this.toNavs.goods_category[i])
					}
				}
			})
			// 错误处理
			.catch(error => {
				console.log(error);
			});
	},
	destroyed() {
		window.removeEventListener('scroll', this.getScroll)
	},
	methods: {
		// 侧边栏
		selectMenu() {
			this.closeEl()
		},
		openEl() {
			// console.log(this.$refs.elCol.$el)
			let ele = this.$refs.elCol.$el
			$('.el-col').stop().slideDown(300);
			$('.icon-danlieliebiao').hide()
			$('.icon-guanbi2').show()
			$('.side-bar').css('position', 'fixed')
		},
		closeEl() {
			let ele = this.$refs.elCol.$el
			$('.el-col').stop().slideUp(300);
			$('.icon-danlieliebiao').show()
			$('.icon-guanbi2').hide()
			$('.side-bar').css('position', 'static')
			$('.side-bar').css('float', 'right')
		},
		// 滚动事件改变顶部背景颜色
		getScroll() {
			if (this.mouseIsTrue == false) {
				this.changeOriginal()
			} else {
				this.changeBg()
			}
		},
		// 鼠标移入改变背景颜色 字体颜色 和 logo
		changeBg() {
			// 改变背景颜色
			document.querySelector('.header').style.backgroundColor = '#fff';
			// 改变字体颜色
			let a = document.querySelectorAll('.a')
			for (let i = 0; i < a.length; i++) {
				a[i].style.color = '#000'
			}
			//适配列表图标
			document.querySelector('.icon-danlieliebiao').style.color = '#003b83'
			document.querySelector('.icon-guanbi2').style.color = '#003b83'
			// 改变logo
			this.logo = require("../../static/img/tyd_logo.jpg")
			//鼠标移入
			this.mouseIsTrue = true
		},
		// 鼠标移出改变背景颜色 字体颜色 和 logo
		changeOriginal() {
			//鼠标移出
			this.mouseIsTrue = false
			// 滚动距离的兼容性写法
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			// 改变背景颜色
			document.querySelector('.header').style.backgroundColor = '#003b83'
			// if (this.scrollTop >= 96) {
			// document.querySelector('.header').style.backgroundColor = '#003b83'
			// } else {
			// document.querySelector('.header').style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
			//判断是否是首页 
			//如果是 顶部背景显示为透明
			//如果不是 顶部背景显示为黑色
			// if (this.path == '/index') {
			// document.querySelector('.header').style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
			// } else {
			// document.querySelector('.header').style.backgroundColor = '#003b83'
			// }
			// }
			// 改变字体颜色
			let a = document.querySelectorAll('.a')
			for (let i = 0; i < a.length; i++) {
				a[i].style.color = '#fff'
			}
			//适配列表图标
			document.querySelector('.icon-danlieliebiao').style.color = '#fff'
			document.querySelector('.icon-guanbi2').style.color = '#fff'
			// 改变logo
			this.logo = require("../../static/img/白logo.png")
		},
		//搜索框显示与隐藏
		search() {
			document.querySelector('.search').style.display = 'block'
			document.querySelector('.head').style.display = 'none'
		},
		searchOff() {
			document.querySelector('.search').style.display = 'none'
			document.querySelector('.head').style.display = 'block'
			this.inputVal = ''
		},
		//商城显示与隐藏
		shoppping(e) {
			if (this.shop == 0) {
				e.currentTarget.lastElementChild.style.display = 'block'
				this.shop = 1
			} else {
				e.currentTarget.lastElementChild.style.display = 'none'
				this.shop = 0
			}
		},
		// input改变时 显示搜索信息 做节流
		inputInp: throttle(function (e) {
			// console.log('e' + e.target.value.replace(/\ +/g, ""));
			// 请求后台数据
			this.$axios.get('/qkj/v1.home/keyword', {
				params: {
					q: e.target.value.replace(/\ +/g, "")
				}
			})
				.then(response => {
					// console.log(response.data.data.data);
					this.inputMsg = response.data.data.data
					// console.log(this.inputMsg);
				})
				// 错误处理
				.catch(error => {
					console.log(error);
				});
		}, 500),
		//点击导航栏跳转到指定位置
		// goPosition(id) {
		// 	let top = document.getElementById(id).offsetTop;
		// 	window.scrollTo({
		// 		top: top - 96,
		// 		behavior: 'smooth'
		// 	});
		// }
		//点击导航栏跳转到指定位置
		goPosition(path, id) {
			var path = path
			var Id = id;
			// 将每个模块点击的id存储到本地 方便服务中心组件获取
			localStorage.setItem('toServiceId', Id);
			// 如果当前页面是服务中心首页 则直接滚动
			// 如果不是 则先跳转至服务中心首页再进行操作
			if (this.$route.path != '/service/serviceCon') {
				this.$router.push(path);
			} else {
				// console.log(document.getElementById(id).offsetTop)
				let top = document.getElementById(id).offsetTop
				window.scrollTo({
					top: top - 96,
					behavior: 'smooth'
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.side-bar {
	width: 50%;
	// position: fixed;
	right: 0;
	top: 96px;
	bottom: 0;
	overflow: hidden;

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden;
	}
}

@keyframes anim {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// 侧栏
.el-col-12 {
	width: 100%;
	// float: right;
	margin-top: -1px;
	border-left: 2px solid #003b83;
	border-top: 2px solid #003b83;
	border-bottom: 2px solid #003b83;
	display: none;

	@media screen and (min-width:1200px) {
		display: none !important;
	}
}


.el-menu-item-group {
	background-color: #ecf5ff;
}

/deep/ .el-submenu__title:hover {
	background-color: #d3dfec;
}

/deep/ .el-menu-item:hover {
	background-color: #d3dfec;
}

/deep/ .el-submenu .el-menu-item:hover {
	background-color: #ecf5ff;
}

.el-submenu .el-menu-item a {
	color: #000;
	display: block;
	width: 100%;
	height: 100%;
}

/deep/ .el-submenu__title i {
	color: #000;
}

// /deep/ .el-menu-item-group>ul{
// 	display: flex;
// 	flex-wrap: wrap;
// }
// .el-submenu .el-menu-item:nth-child(1){
// 	width: 100%;
// }
// .el-submenu .el-menu-item{
// 	width: 50%;
// 	min-width: 100px;
// }
// 搜索框
.search {
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: absolute;
	top: 0;
	display: none;
	animation: anim .6s;
}

.search_box {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 96px;
	left: 0;
	opacity: .5;
	background-color: #000;
	z-index: 990;
}

.search_content {
	width: 1200px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.search_img {
	width: 230px;

	img {
		width: 100%;
	}
}

.search_text {
	width: 920px;
	height: 34px;
	display: flex;
	align-items: center;
}

.search-input {
	width: 700px;
	height: 24px;
	font-size: 18px;
	background: 0 0;
	border: none;
	padding-left: 12px;
	outline: none;
}

.search_sousuo {
	font-size: 24px;
}

.icon-guanbi {
	margin-left: 150px;
	font-size: 24px;
}

// 搜索内容
.search_push {
	// display: none;
	position: absolute;
	top: 96px;
	background-color: rgba(255, 255, 255, .9);
	width: 679px;
	left: 54%;
	border-top: 1px solid #e7eef3;
	line-height: 16px;
	border-radius: 2px;
	padding: 16px 0;
	z-index: 999;
	transform: translate(-50%, 0);

	h3 {
		padding: 8px 0;
		margin: 0 40px;
		color: #919699;
		font-weight: 500;
		font-size: 16px;
	}

	.search_list a {
		padding: 8px 40px;
		display: inline-block;
		width: 100%;
		font-size: 16px;
		line-height: 16px;
		color: #303233 !important;
		box-sizing: border-box;
	}

	.search_list a:hover {
		background-color: rgba(251, 169, 25, .4);
	}
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: #fff;
}

/* .active{
  color: #000;
} */
.header {
	width: 100%;
	height: 96px;
	box-sizing: border-box;
	background-color: #003b83;
	position: fixed;
	top: 0;
	z-index: 999;
	border-bottom: 1px solid gray;
}

.head {
	height: 100%;
	animation: anim .6s;
	z-index: 999;

	@media screen and (max-width:1199px) {
		position: relative;
	}
}

.head:hover .icon-danlieliebiao {
	color: #003b83;
}

.head:hover .icon-guanbi2 {
	color: #003b83;
}

.header_content {
	width: 1200px;
	height: 100%;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;

	@media screen and (max-width:1199px) {
		width: 100%;
		justify-content: center;
	}
}
// 移动端列表样式
.header_iphone_ul{
	padding-bottom: 20px;
}
.header_iphone_english{
		display: flex;
		justify-content: end;
		align-items: center;
		padding-right: 20px;
		height: 56px;
		span{
			color: #FBA919;
			font-size: 22px;
		}
	}
.header_iphone_right {
	position: absolute;
	top: 0;
	right: 17px;
	width: 30px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (min-width:1200px) {
		display: none;
	}

	@media screen and (min-width:992px) and (max-width:1199px) {
		font-size: 28px;
	}

	@media screen and (min-width:768px) and (max-width:991px) {
		font-size: 26px;
	}

	@media screen and (max-width:767px) {
		font-size: 24px;
	}

	
	.icon-danlieliebiao {
		color: #fff;
		font-size: 30px;

		@media screen and (min-width:992px) and (max-width:1199px) {
			font-size: 28px;
		}

		@media screen and (min-width:768px) and (max-width:991px) {
			font-size: 26px;
		}

		@media screen and (max-width:767px) {
			font-size: 24px;
		}
	}
	.el-submenu__icon-arrow{
		right: 25px;
	}
	.icon-guanbi2 {
		display: none;
		color: #fff;
		font-size: 24px;

		@media screen and (min-width:992px) and (max-width:1199px) {
			font-size: 22px;
		}

		@media screen and (min-width:768px) and (max-width:991px) {
			font-size: 20px;
		}

		@media screen and (max-width:767px) {
			font-size: 18px;
		}
	}
}

.header::before {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	content: "";
	background-color: rgba(255, 255, 255, 0.2);
	z-index: -1;
}

.header_left,
.header_center,
.header_right {
	height: 100%;
	display: flex;
	align-items: center;
}

.header_right {
	@media screen and (max-width:1199px) {
		display: none;
	}
}

.header_left {
	width: 230px;

	@media screen and (max-width:767px) {
		width: 200px;
	}

	a {
		width: 100%;

		img {
			vertical-align: bottom;
		}

		@media screen and (min-width:1199px) {
			// height: 55px;
		}
	}
}

.header_left img {
	width: 100%;
	height: 100%;
}

.header_center {
	width: 790px;
	justify-content: space-between;
	font-size: 18px;
	font-family: SourceHanSansCN;
	font-weight: 400;
	color: #171717;
	line-height: 34px;
	display: flex;

	@media screen and (max-width:1199px) {
		display: none;
	}
}

// .header_center li:hover .header_list {
//   display: block;
// }

.header_list {
	display: none;
	width: 100%;
	height: 94px;
	position: absolute;
	top: 96px;
	left: 0;
	background-color: rgba(255, 255, 255, .9);
	box-sizing: border-box;
	text-align: center;
	color: #000;
}

.header_center dl {
	width: 100%;
	// height: 100%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 30px 0 30px;
}

.header_listProduct {
	height: 420px;

	@media screen and (min-width:1200px) and (max-width:1549px) {
		height: 350px;
	}

	dl {
		justify-content: space-evenly;
	}

	dd {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: 170px;

		@media screen and (min-width:1200px) and (max-width:1549px) {
			width: 130px;
		}
	}

	dd:last-child {
		width: 550px;
		padding-top: 40px;

		@media screen and (min-width:1200px) and (max-width:1549px) {
			width: 400px;
		}

		img {
			width: 100%;
		}
	}
}

.product_dl {
	a {
		display: inline-block;
		width: 100%;
		height: 100%;

		// @media screen and (min-width:1200px) and (max-width:1549px) {
		// 	font-size: 16px;
		// }
	}

	img {
		width: 170px;

		@media screen and (min-width:1200px) and (max-width:1549px) {
			width: 130px;
		}
	}
}

// .product_dl:not(:nth-child(6n)) {
//   margin-right: calc(5% / 2);
// }

.header_center dd {
	// width: 170px;
	padding-left: 40px;
	padding-right: 40px;
	// height: 50px;
	// line-height: 50px;
	color: #000;
}

.header_center dd>a {
	color: #000 !important;
}


.header_center dd:hover a {
	color: #fba919 !important;
}

.active {
	color: #FBA919 !important;
}

/* .header_center li:hover {
  color: #000;
} */
.header_center li {
	height: 100%;
	// padding: 0 17px;
}

.header_li {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}

.header_li:hover a {
	color: #FBA919 !important;
}

.header_li a::before {
	content: "";
	width: 100%;
	height: 3px;
	border-radius: 5px;
	background-color: #fba919;
	position: absolute;
	bottom: -3px;
	transition: transform 500ms ease;
	transform: scale(0) translateY(-50%);
	z-index: 999;
}

/* .header_center li:hover > a {
  color: var(--thm-black);
} */
.header_center a.active::before,
.header_li:hover>a::before {
	transform: scale(1) translateY(-50%);
	background-color: #fba919;
}

.header_shop {
	color: #FBA919;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 22px;
	position: relative;

	span {
		font-size: 18px;
		line-height: 22px;
		margin-left: 6px;
	}

	.header_fnt {
		display: none;
		position: absolute;
		left: -35px;
		top: 59px;
		background: #fff;
		width: 150px;
		z-index: 9;
		box-shadow: 0px 0.25rem 1rem 0px rgb(0 0 0 / 10%);
	}

	.header_shopping {
		padding: 10px 6px;
		width: 150px;
		overflow: hidden;

		a {
			float: left;
			margin: 0 3px;
			display: block;
			overflow: hidden;
			width: 40px;
			height: 40px;

			img {
				width: 40px;
				height: 40px;
				-webkit-transition: opacity .4s ease-in, -webkit-transform .6s linear;
				transition: opacity .4s ease-in, transform .6s linear;
				transform: scale(1);
			}

			img:hover {
				transform: scale(1.1);
			}
		}
	}
}

.header_icon {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-left: 10px;
	cursor: pointer;

	div,span {
		color: #FBA919;
		font-size: 22px;
	}
}
.header_en{
	padding-top: 3px;
}
.header_icon:hover {
	background-color: rgba(255, 255, 255, .1);
}

.header_sousuo {
	font-size: 22px;
}
</style>
