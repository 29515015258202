<!-- 新闻资讯 -->
<template>
  <div>
    <!-- 导航栏 -->
    <navs />
    <!-- 内容 -->
    <keep-alive>
      <router-view />
    </keep-alive>
    <!-- 底部 -->
    <foot />
    <!-- 回到顶部 -->
    <call />
  </div>
</template>

<script>
import Navs from '@/components/index/navs.vue';
import Foot from '@/components/index/foot.vue';
import Call from '@/components/index/BackTop.vue';
export default {
  components: { Navs, Foot, Call },
  mounted() {
    // 设置项目title
    document.title = '新闻资讯_天佑德青稞酒官网'
  },
}
</script>

<style lang="less" scoped>
</style>