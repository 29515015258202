<!-- 息息相通前期版本 -->
<template>
  <div class="pku_linkage">
    <h3 class="pku_linkageTit">息息相通</h3>
    <div class="pku_linkageCon">
      <div class="myLinkSwiper swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="item in tolinkageList"
            :key="item.id"
          >
            <div class="linkswiper_left">
              <router-link :to="'/news/newDetails/' + item.id" target="_blank">
                <img v-lazy="item.thumbnail" alt="" />
              </router-link>
              <p class="linkage_leftTit">{{ item.post_title }}</p>
            </div>
            <div class="linkswiper_right">
              <router-link :to="'/news/newDetails/' + item.id" target="_blank">
                <h3>{{ item.post_title }}</h3>
              </router-link>
              <p class="linkswiper_rigText">{{ item.post_excerpt }}</p>
              <p class="linkswiper_rigTime">{{ item.published_time }}</p>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div
          class="linkswiper-pagination"
          v-if="this.tolinkageList.length >= 2"
        ></div> -->
        <div class="linkswiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkSwiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  props: {
    tolinkageList: Array,
  },
  data() {
    return {};
  },
  mounted() {
    var mylinkswiper = new LinkSwiper(".myLinkSwiper", {
      pagination: {
        el: ".linkswiper-pagination",
        clickable: true, //点击分页器的指示点分页器会控制Swiper切换
        bulletActiveClass: "my-bullet-active", //圆点点击时的样式类名
      },
      // 自动滑动
      // autoplay: {
      //     delay: 3000
      // },
      loop: true, // 循环模式选项
      observer: true,
      observerParents: true,
    });
  },
};
</script>

<style lang="less" scoped>
.pku_linkage {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 4% 50px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 991px) {
    padding: 0 4%;
  }
}
// 标题
.pku_linkageTit {
  font-size: 36px;
  font-weight: 400;
  padding: 45px 0 30px;

  @media screen and (max-width: 767px) {
    font-size: 30px;
    font-weight: 500;
  }
}
// 内容
.linkswiper_left {
  width: 58%;
  position: relative;
  overflow: hidden;
  a{
    display: inline-block;
  }
  .linkage_leftTit {
    position: absolute;
    bottom: 0;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    padding: 0 15px;
    line-height: 55px;
    color: #fff;
    // box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.6);
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}
.linkswiper_right {
  width: 42%;
  padding: 35px 15px 15px 20px;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 20px 15px 15px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    background-color: #edeeee;
  }
  @media screen and (min-width: 992px) {
    background: url(@/static/img/pku-swiperBg.png) no-repeat;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  h3 {
    font-size: 22px;
    font-weight: 400;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .linkswiper_rigText {
    padding: 20px 0 0;
    letter-spacing: 1px;
    line-height: 32px;
    @media screen and (max-width: 991px) {
      padding: 15px 0 0;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .linkswiper_rigTime {
    @media screen and (min-width: 992px) {
      position: absolute;
      bottom: 20px;
    }
    @media screen and (max-width: 991px) {
      padding: 20px 0 0;
    }
  }
}
//swiper
.pku_linkageCon {
  position: relative;
  width: 100%;
  // height: 500px;
}
.linkSwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  // text-align: center;
  // font-size: 18px;
  background: #fff;
  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.swiper-slide img {
  display: inline-block;
  width: 100%;
  // height: 100%;
  // object-fit: cover;
}

.pku_linkageCon {
  .myLinkSwiper {
    .swiper-pagination-bullets {
      width: 100px;
      left: auto;
      bottom: 20px;
    }
  }
}
.linkswiper-pagination {
  // width: 100px !important;
  position: absolute;
  right: 13px;
  // left: auto !important;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 22.5px;
  @media screen and (max-width: 991px) {
    bottom: 15px;
  }
}
.swiper-container ::v-deep .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.6;
}
.swiper-container ::v-deep .my-bullet-active {
  background: #fba919;
  opacity: 1;
}
.swiper-pagination-bullet {
  display: none;
}
</style>