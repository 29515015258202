<!-- 首页酿造车间 -->
<template>
  <div>
    <div class="swiper_con">
      <h2>国家级非物质文化遗产酿造技艺</h2>
      <div class="swiper_con_text">
        <p>青海青稞酒传统酿造技艺</p>
        <p>原料清蒸·辅料清蒸·清茬发酵·清蒸馏酒</p>
      </div>
    </div>
    <div id="swiper" class="swiper mySwiper">
      <!-- 分页器 导航栏 -->
      <div class="swiper-pagination"></div>
      <div class="swiper-wrapper" ref="swiperList">
        <!-- <div class="swiper-slide" v-for="item in LunBoCarList" :key="item.kid">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div> -->

        <!-- <div class="swiper-slide">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div>
      <div class="swiper-slide">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div>
      <div class="swiper-slide">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div>
      <div class="swiper-slide">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div>
      <div class="swiper-slide">
        <img src="../../static/img/vr1.png" alt="" />
        <div class="swiper_text">
          <p class="swiper_title">酿造车间</p>
          <a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>
        </div>
      </div> -->
      </div>
      <!-- 箭头 -->
      <!-- <div class="swiper-button-next"></div> -->
      <!-- <div class="swiper-button-prev"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {
      LunBoCarList: "",
    };
  },
  created() {
    this.$axios
      .get("/qkj/v1.home/index")
      .then((response) => {
        this.LunBoCarList = response.data.data.data.atlas;
        // console.log(response.data.data.data);
        this.foratlas();
        this.getSwiperTab();
      })
      // 错误处理
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {},
  methods: {
    foratlas() {
      var totalprice = "";
      for (let i = 0; i < this.LunBoCarList.length; i++) {
        totalprice +=
          '<div class="swiper-slide">' +
          '<img src="' +
          this.LunBoCarList[i].thumbnail +
          '" alt="" />' +
          '<div class="swiper_text">' +
          '<p class="swiper_title">' +
          this.LunBoCarList[i].post_title +
          "</p>" +
          '<a href="https://720yun.com/t/f22jvghwvw4?scene_id=96978676" target="_blank">酒厂VR场景></a>' +
          "</div></div>";
      }
      this.$refs.swiperList.innerHTML = totalprice;
    },
    getSwiperTab() {
      var that = this;
      var mySwiper = new Swiper(".swiper", {
        autoPlay: true,
        loop: true, // 循环播放
        slidesPerView: "auto", //自定义宽度
        freeMode: true, // 自由模式
        delay: 500,
        sticky: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            let text = "";
            // console.log(index, 111);
            switch (index) {
              case 0:
                text = that.LunBoCarList[0].post_title;
                break;
              case 1:
                text = that.LunBoCarList[1].post_title;
                break;
              case 2:
                text = that.LunBoCarList[2].post_title;
                break;
              case 3:
                text = that.LunBoCarList[3].post_title;
                break;
              case 4:
                text = that.LunBoCarList[4].post_title;
                break;
              case 5:
                text = that.LunBoCarList[5].post_title;
                break;
              case 6:
                text = that.LunBoCarList[6].post_title;
                break;
              case 7:
                text = that.LunBoCarList[7].post_title;
                break;
            }
            return '<span class="' + className + '">' + text + "</span>";
          },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swiper_con {
  background-color: #e1e1e1;
}

.swiper_con h2 {
  padding-bottom: 25px;
  text-align: center;
  font-size: 40px;

  @media screen and (max-width: 767px) {
    font-size: 30px;
    padding: 0 4% 25px;
  }
}

.swiper_con_text {
  text-align: center;
  font-size: 18px;
  padding-bottom: 40px;
  p {
    line-height: 30px;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

/deep/ .swiper-slide-active .swiper_text {
  // display: block;
  display: none;
}

/deep/ .swiper-slide {
  overflow: hidden;
  width: auto;
  height: 600px;
  margin-right: -1px;
}

/deep/ .swiper-slide-active {
  overflow: hidden;
}

/deep/ .swiper-slide-active img {
  -webkit-transition: opacity 0.4s ease-in, -webkit-transform 0.6s linear;
  transition: opacity 0.4s ease-in, transform 0.6s linear;
  transform: scale(1);
}

// /deep/ .swiper-slide-active:hover img {
//   transform: scale(1.05);
// }

/deep/ .swiper_text {
  display: none;
  position: absolute;
  top: 0;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding-left: 12px;
  }

  a:hover {
    text-decoration: underline;
  }
}

/deep/ .swiper_title {
  font-size: 26px;
  font-weight: 500;
  color: #f3f3f3;
  margin: 40px 0 10px;
}

/deep/ .swiper {
  --swiper-theme-color: #ff6600;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px;
  /* 设置按钮大小 */
}

/deep/.swiper-button-prev {
  //左箭头
  left: 28.5%;
  right: auto;
  top: 61%;
  color: #fff;
}

/deep/.swiper-button-next {
  //右箭头
  right: 28.5%;
  left: auto;
  top: 61%;
  color: #fff;
}

/deep/ ul {
  list-style: none;
  display: flex;
}

/deep/ li {
  width: 80px;
  height: 50px;
  background-color: red;
}

.swiper {
  width: 100%;
  // min-width: 1200px;
  position: relative;
  overflow: hidden;
}

/deep/ .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/deep/ .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/deep/ .swiper-pagination {
  height: 60px;
  position: static;
  background-color: #e1e1e1;

  @media screen and (max-width: 991px) {
    display: none;
  }
}

/deep/.swiper-pagination-bullet {
  width: 140px;
  height: 60px;
  border-radius: 0%;
  line-height: 60px;
  color: #fff;
  font-size: 21px;
  opacity: 1;
  margin: 0 10px !important;
}

/deep/ .swiper-pagination-bullet-active {
  color: #000;
  background-color: #fff;
  font-size: 21px;
  font-weight: 500;
}
</style>
