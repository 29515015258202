import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Company from '../views/company.vue'
import News from '../views/news.vue'
import Research from '../views/Research.vue'
import Product from '../views/product.vue'
import Service from '../views/service.vue'
import Investor from '../views/investor.vue'
import Pku from '../views/pku.vue'
import page404 from '../views/page404.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: 'index',
		meta: {
			title: '首页'
		}
	},
	// 首页
	{
		path: '/index',
		component: Index
	},
	// 走进天佑德
	{
		path: '/company',
		component: Company,
		children: [{
				path: '/',
				redirect: 'profile'
			},
			{
				path: 'profile',
				component: () => import('../components/company/profile.vue')
			},
			{
				path: 'history',
				component: () => import('../components/company/history.vue')
			},
			// { path: 'framework', component: () => import('../components/company/framework.vue') },
			// { path: 'purpose', component: () => import('../components/company/purpose.vue') },
			{
				path: 'honor',
				component: () => import('../components/company/honor.vue')
			},
			{
				path: 'duty',
				component: () => import('../components/company/duty.vue')
			},
			{
				path: 'tour',
				component: () => import('../components/company/tour.vue')
			},
			{
				path: 'propaganda',
				component: () => import('../components/company/propaganda.vue')
			},
			{
				path: 'cooperation',
				component: () => import('../components/company/cooperation.vue')
			},
			// 文化天佑德
			{
				path: 'culture',
				component: () => import('../components/company/culture/culture.vue'),
				children: [{
						path: '/',
						redirect: 'enterprise'
					},
					{
						path: 'brand',
						component: () => import('../components/company/culture/brand.vue')
					},
					{
						path: 'tianniang',
						component: () => import('../components/company/culture/TianNiang.vue')
					},
					{
						path: 'sanctum',
						component: () => import('../components/company/culture/sanctum.vue')
					},
					{
						path: 'enterprise',
						component: () => import('../components/company/culture/enterprise.vue')
					},
					{
						path: 'qingzang',
						component: () => import('../components/company/culture/QingZang.vue')
					},
				]
			},
		]
	},
	// 产品中心
	{
		path: '/product',
		component: Product,
		// redirect: "/product/index",
		children: [
			{ path: '/', redirect: 'index' },
			{
				path: 'index',
				component: () => import('../components/product/productIndex.vue')
			},
			{
				path: 'list_:id',
				component: () => import('../components/product/productList.vue'),
				children: [
					{
						path: '/',
						redirect: 'listCon'
					},
					{
						path: 'listCon',
						component: () => import('../components/product/productListCon.vue')
					},
					{
						path: 'listCon:sid?',
						component: () => import('../components/product/productListCon.vue')
					},
				]
			},
			{
				path: 'details_:id',
				component: () => import('../components/product/productCon.vue')
			},
		]
	},
	// 新闻资讯
	{
		path: '/news',
		component: News,
		children: [{
				path: '/',
				redirect: '35'
			},
			{
				path: '35',
				component: () => import('../components/news/newsIndex.vue')
			},
			{
				path: ':id',
				component: () => import('../components/news/newContent.vue')
			}, ,
		]
	},
	{
		path: '/news/newDetails/:id',
		name: 'newDetails',
		component: () => import('../components/news/newDetails.vue')
	},
	// 青稞研究院
	{
		path: '/research',
		component: Research,
		children: [{
				path: '/',
				redirect: 'healthy'
			},
			{
				path: 'healthy',
				component: () => import('../components/research/healthy.vue')
			},
			{
				path: ':id',
				component: () => import('../components/news/newContent.vue')
			},
		]
	},
	// 服务中心
	{
		path: '/service',
		component: Service,
		children: [{
				path: '/',
				redirect: 'serviceCon'
			},
			{
				path: 'serviceCon',
				component: () => import('../components/service/serviceCon.vue')
			},
			{
				path: 'lookDetails/:id',
				component: () => import('../components/service/lookDetails.vue')
			},
		]
	},
	// 投资者关系
	{
		path: '/investor',
		component: Investor,
		children: [{
				path: '/',
				redirect: '1'
			},
			{
				path: '1',
				component: () => import('../components/investor/investorIndex.vue')
			},
			{
				path: '2',
				component: () => import('../components/investor/investorEducation.vue')
			},
			{
				path: 'investorDetails/:id',
				component: () => import('../components/investor/investorDetails.vue')
			},
		]
	},
	// 天佑德×北大
	{
		path: '/pku',
		component: Pku
	},
	// // 文化天佑德
	// {
	// 	path: '/culture',
	// 	component: Culture,
	// 	children: [{
	// 			path: '/',
	// 			redirect: 'enterprise'
	// 		},
	// 		{
	// 			path: 'brand',
	// 			component: () => import('../components/culture/brand.vue')
	// 		},
	// 		{
	// 			path: 'tianniang',
	// 			component: () => import('../components/culture/TianNiang.vue')
	// 		},
	// 		{
	// 			path: 'sanctum',
	// 			component: () => import('../components/culture/sanctum.vue')
	// 		},
	// 		{
	// 			path: 'enterprise',
	// 			component: () => import('../components/culture/enterprise.vue')
	// 		},
	// 		{
	// 			path: 'qingzang',
	// 			component: () => import('../components/culture/QingZang.vue')
	// 		},
	// 	]
	// },
	// 404page
	{
		path: '*',
		name: 'page404',
		component: page404,
	}
]

const router = new VueRouter({
	// mode:'history',
	routes,
})

export default router
