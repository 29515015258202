<!-- 天佑德运营大数据 -->
<template>
  <div class="latest" ref="latest">
    <img class="latest_bg" src="../../static/img/latest_bg.png" alt="" />
    <div class="latest_content">
      <h2>天佑德运营大数据</h2>
      <p class="latest_text">全面了解天佑德运营实时数据</p>
      <iframe id="iframe_data" src="http://dp.qkj.com.cn/" frameborder="0"></iframe>
      <!-- 数据 -->
      <div class="latest_container">
        <ul>
          <li>
            <div class="latest_icon">
              <img src="../../static/img/latestImg1.png" alt="">
            </div>
          </li>
          <li>
            <div class="latest_icon">
              <img src="../../static/img/latestImg2.png" alt="">
            </div>
          </li>
          <li>
            <div class="latest_icon">
              <img src="../../static/img/latestImg3.png" alt="">
            </div>
          </li>
          <li>
            <div class="latest_icon">
              <img src="../../static/img/latestImg4.png" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 背景图 -->
    <!-- <div class="latest_bg"></div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.latest {
  position: relative;
  @media screen and (max-width:1024px) {
  	display: none;
  }
}

// 背景图
.latest_bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 60%;
}

h2 {
  margin: 72px 0 25px;
  text-align: center;
  font-size: 40px;
}

.latest_text {
  text-align: center;
  font-size: 18px;
  padding-bottom: 30px;
}

.latest_content {
  width: 1200px;
  margin: 0 auto;
  @media screen and (min-width:1025px) and (max-width:1199px) {
  	width: 100%;
  }
}

// 数据
#iframe_data {
  width: 1200px;
  height: 750px;
  @media screen and (min-width:1025px) and (max-width:1199px) {
  	width: 100%;
  }
}

// 图片
.latest_imgs {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.latest_img {
  width: 30%;
  border-radius: 20px;
  overflow: hidden;
}

.latest_img img {
  width: 100%;
  height: 100%;
}

// 数据
.latest_container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.latest_container ul {
  list-style: none;
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}

.latest_container li:first-child {
  border-left: 1px solid #fff;
}

.latest_container li {
  width: 25%;
  border-right: 1px solid #fff;
  color: #fff;
  display: flex;
  align-items: center;
  flex-flow: column;
}

// 图标
.latest_icon {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.latest_icon img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

// 数
.latest_container h3 {
  font-size: 40px;
  font-weight: 500;
  margin: 10px 0;
}

.latest_container p {
  font-size: 22px;
}
</style>