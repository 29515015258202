<!-- 青稞之旅 -->
<template>
  <div class="tour">
    <!-- 导航栏 -->
    <navs />
    <!-- 内容 -->
    <!-- <div class="tour_con">
      青稞研究院更新中。。。
    </div> -->
	<router-view></router-view>
    <!-- 底部 -->
    <foot />
    <!-- 回到顶部 -->
    <call />
  </div>
</template>

<script>
import Navs from '@/components/index/navs.vue';
import Foot from '@/components/index/foot.vue';
import Call from '@/components/index/BackTop.vue';
export default {
  components: { Navs, Foot, Call },
  mounted() {
    // 设置项目title
    document.title = '青稞研究院_天佑德青稞酒官网'
  }
}
</script>

<style lang="less" scoped>
.tour_con {
  padding: 96px 0 70px;
  width: 1200px;
  margin: 0 auto;
  font-size: 40px;
}
</style>