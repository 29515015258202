<!-- 底部 -->
<template>
	<div class="footer">
		<div class="footer_head">
			<!-- 背景图 -->
			<div class="footer_bg"></div>
			<!-- 文字内容 -->
			<div class="footer_cen">
				<div class="footer_content">
					<div class="footer_logo">
						<div class="footer_img">
							<img src="../../static/img/foot_logo.png" alt="" />
						</div>
						<ul class="footer_iphone_icon">
							<li>
								<a href="tel:4009-969-969">
									<div class="footer_iphone_icons icon-telephone"></div>
									<p class="footer_iphone_text">电话</p>
								</a>
							</li>
							<li>
								<a href="mailto:tydhelp@qkj.com.cn">
									<div class="footer_iphone_icons icon-email"></div>
									<p class="footer_iphone_text">邮箱</p>
								</a>
							</li>
							<li>
								<a href="https://map.baidu.com/search/%E9%9D%92%E6%B5%B7%E4%BA%92%E5%8A%A9%E9%9D%92%E7%A8%9E%E9%85%92%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@11349833.345,4390732.79,18.5z?querytype=s&da_src=shareurl&wd=%E9%9D%92%E6%B5%B7%E4%BA%92%E5%8A%A9%E9%9D%92%E7%A8%9E%E9%85%92%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=69&src=0&wd2=%E6%B5%B7%E4%B8%9C%E5%B8%82%E4%BA%92%E5%8A%A9%E5%9C%9F%E6%97%8F%E8%87%AA%E6%B2%BB%E5%8E%BF&pn=0&sug=1&l=19&b=(11349127.511814626,4391403.042395389;11349995.55776924,4391826.666905532)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=e136e1a8f8ecd3efb42655b7&device_ratio=1s"
									target="_blank">
									<div class="footer_iphone_icons icon-pin"></div>
									<p class="footer_iphone_text">地址</p>
								</a>
							</li>
						</ul>
						<ul class="footer_icon">
							<li>
								<a href="https://baike.baidu.com/item/%E9%9D%92%E6%B5%B7%E4%BA%92%E5%8A%A9%E5%A4%A9%E4%BD%91%E5%BE%B7%E9%9D%92%E7%A8%9E%E9%85%92%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/61129765?fr=aladdin"
									target="_blank"><i class="iconfont icon-baidu"></i></a>
							</li>
							<li>
								<a href="https://weibo.com/qhhzqkjxsyxgs" target="_blank"><i
										class="iconfont icon-weibo1"></i></a>
								<div class="footer_pupup">
									<div class="footer_code">
										<img src="../../static/img/er-wb.png" alt="">
									</div>
									<div class="footer_triangle"></div>
								</div>
							</li>
							<li>
								<a>
									<i class="iconfont icon-weixin1"></i>
								</a>
								<div class="footer_pupup">
									<div class="footer_code">
										<img src="../../static/img/er-wx.jpg" alt="">
									</div>
									<div class="footer_triangle"></div>
								</div>
							</li>
							<li>
								<a href="https://www.douyin.com/user/MS4wLjABAAAA2KsPqg4Reb7EEnBG-wNHauOHhWt6vDOVm3ja1OxRcgo"
									target="_blank"><i class="iconfont icon-douyin"></i></a>
								<div class="footer_pupup">
									<div class="footer_code">
										<img src="../../static/img/er-dy.png" alt="">
									</div>
									<div class="footer_triangle"></div>
								</div>
							</li>
						</ul>
						<div class="footer_xs">
							<img src="../../static/img/foot-xs.png" alt="">
						</div>
					</div>
					<dl class="footer_text footer_hover footer_lj">
						<dt>链接</dt>
						<dd>
							<router-link to="/company">走进天佑德</router-link>
						</dd>
						<dd>
							<router-link to="/product">产品中心</router-link>
						</dd>
						<dd>
							<router-link to="/news">新闻资讯</router-link>
						</dd>
						<dd>
							<router-link to="/investor">投资者关系</router-link>
						</dd>
					</dl>
					<dl class="footer_text footer_we">
						<dt>联系我们</dt>
						<dd><a href="tel:4009-969-969"><span class="icon-telephone"></span>4009-969-969</a></dd>
						<dd><a href="mailto:tydhelp@qkj.com.cn"><span class="icon-email"></span>tydhelp@qkj.com.cn</a></dd>
						<dd>
							<a href="https://map.baidu.com/search/%E9%9D%92%E6%B5%B7%E4%BA%92%E5%8A%A9%E9%9D%92%E7%A8%9E%E9%85%92%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@11349833.345,4390732.79,18.5z?querytype=s&da_src=shareurl&wd=%E9%9D%92%E6%B5%B7%E4%BA%92%E5%8A%A9%E9%9D%92%E7%A8%9E%E9%85%92%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=69&src=0&wd2=%E6%B5%B7%E4%B8%9C%E5%B8%82%E4%BA%92%E5%8A%A9%E5%9C%9F%E6%97%8F%E8%87%AA%E6%B2%BB%E5%8E%BF&pn=0&sug=1&l=19&b=(11349127.511814626,4391403.042395389;11349995.55776924,4391826.666905532)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=e136e1a8f8ecd3efb42655b7&device_ratio=1s"
								target="_blank">
								<span class="icon-pin"></span>青海省互助县威远镇西大街6号
							</a>
						</dd>
					</dl>
					<dl class="footer_text footer_hover footer_fw">
						<dt>服务中心</dt>
						<dd @click="goPosition('mail')">
							<router-link to="/service/serviceCon">公司通讯录</router-link>
						</dd>
						<dd @click="goPosition('sales')">
							<router-link to="/service/serviceCon">销售网络</router-link>
						</dd>
					</dl>
				</div>
			</div>
		</div>
		<!-- 底部公司标识 -->
		<div class="footer_foot">
			<p>Copyright©2011-{{this.year}} 青海互助天佑德青稞酒股份有限公司.</p>
			<p><a href="https://beian.miit.gov.cn" target="_blank">京ICP备12037065号-6</a></p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				year: ''
			}
		},
		mounted() {
			this.year = new Date().getFullYear()
		},
		methods: {
			//点击导航栏跳转到指定位置
			goPosition(id) {
				let top = document.getElementById(id).offsetTop;
				window.scrollTo({
					top: top - 96,
					behavior: 'smooth'
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	//底部整体
	.footer_head {
		width: 100%;
		// height: 350px;
		background-color: #232323;
		position: relative;

		@media screen and (min-width:1200px) {
			height: 350px;
		}
	}

	//背景图
	.footer_bg {
		position: absolute;
		top: 0;
		width: 100%;
		background: url(../../static/img/site-footer-mape.png) no-repeat center;
		height: 100%;
		opacity: 0.2;

		@media screen and (max-width:767px) {
			width: 0;
		}
	}

	// 文字内容
	.footer_icon {
		margin: 58px 0 90px;
	}

	.footer_icon a {
		text-decoration: none;
		color: #a5a4a5;
	}

	.footer_icon li:hover {
		background-color: rgba(255, 255, 255, .09);
	}

	.footer_icon li:hover .footer_pupup {
		display: block;

		@media screen and (max-width:1199px) {
			display: none;
		}
	}

	.footer_cen {
		background-color: rgba(0, 59, 185, 0.6);
		height: 100%;
		position: relative;
	}

	.footer_content {
		width: 1200px;
		display: flex;
		padding-top: 50px;
		margin: auto;
		height: 100%;
		color: #fff;
		box-sizing: border-box;

		@media screen and (min-width:768px) and (max-width:1199px) {
			width: 100%;
			justify-content: space-around;
		}

		@media screen and (max-width:767px) {
			width: 100%;
			justify-content: center;
		}
	}

	.footer_img {
		img {
			width: 100%;
		}

		@media screen and (max-width:767px) {
			width: 100%;
			display: flex;
			justify-content: center;

			img {
				width: 162px;
			}
		}
	}

	.footer_xs {
		width: 245px;
		margin-left: -37px;

		@media screen and (max-width:767px) {
			margin-left: 0;
		}
	}

	// 最左侧logo
	.footer_logo {
		width: 162px;
		margin: 52px 165px 0 0;

		img {
			vertical-align: bottom;
		}

		@media screen and (max-width:1199px) {
			margin: 0;
		}

		@media screen and (max-width:767px) {
			width: 80%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	// .footer_logo img:nth-child(1) {
	// 	width: 100%;
	// }

	// .footer_logo p {
	// 	margin: 40px 0 60px;
	// 	font-size: 18px;
	// 	text-align: center;
	// }
	.footer_iphone_icon {
		width: 100%;
		list-style: none;
		display: none;
		margin: 50px 0;

		li {
			width: 25%;
			text-align: center;

			a {
				text-decoration: none;
			}
		}

		.footer_iphone_icons {
			color: #fba919;
			font-size: 22px;
			padding-bottom: 15px;
		}

		.footer_iphone_text {
			color: #a5a4a5;
		}

		@media screen and (max-width:767px) {
			display: flex;
			justify-content: space-evenly;
		}
	}

	.iconfont {
		font-size: 20px;
	}

	.icon-douyin {
		font-size: 16px;
	}

	.footer_icon {
		list-style: none;
		display: flex;
		justify-content: space-between;
		width: 100%;

		@media screen and (max-width:767px) {
			display: none;
		}
	}

	.footer_icon li {
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background-color: #16275c;
		color: #a5a4a5;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.footer_pupup {
		display: none;
		position: absolute;
		bottom: 40px;
		width: 135px;
		height: 135px;
	}

	.footer_triangle {
		position: absolute;
		background-color: #fff;
		box-shadow: 4px 4px 8px rgb(0 0 0 / 10%);
		z-index: 8;
		width: 10px;
		height: 10px;
		bottom: 0px;
		left: 63px;
		transform: translateY(50%) rotate(45deg);
	}

	.footer_code {
		z-index: 99;
		position: absolute;
		padding: 3px;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

		img {
			width: 100%;
			vertical-align: bottom;
		}
	}

	// 链接...
	.footer_lj {
		width: 110px;

		@media screen and (max-width:1199px) {
			display: none;
		}
	}

	.footer_we {
		@media screen and (min-width:768px) and (max-width:1199px) {
			margin-right: 0 !important;
		}

		@media screen and (max-width:767px) {
			display: none;
		}
	}

	.footer_fw {
		@media screen and (max-width:1199px) {
			display: none;
		}
	}

	.footer_text {
		margin-right: 125px;
	}

	.footer_text dt {
		font-size: 16px;
	}

	.footer_text dd {
		font-size: 14px;
		margin-top: 35px;
		position: relative;
		font-weight: 500;
		color: #aeaeae;
		transition: all 500ms ease;
		box-sizing: border-box;

		a {
			text-decoration: none;
			color: #aeaeae;
			display: flex;
			align-items: center;
		}
	}

	.footer_hover dd:hover {
		padding-left: 20px;
		color: #fff;
	}

	.footer_hover dd:before {
		position: absolute;
		top: 50%;
		left: 0;
		bottom: 0;
		width: 10px;
		height: 2px;
		content: "";
		background-color: #fba919;
		transform: translateY(-50%);
		opacity: 0;
		transition: all 500ms ease;
	}

	.footer_hover dd:hover:before {
		opacity: 1;
	}

	.footer_text span {
		margin-right: 20px;
		color: #fba919;
		font-size: 18px;
	}

	.footer_text .icon-email {
		padding-top: 2px;
	}

	.footer_hover dd:hover {
		color: #fff;
		cursor: pointer;
	}

	.footer_we dd:hover {
		color: #fff;
		cursor: pointer;
	}

	// 底部公司标识
	.footer_foot {
		width: 100%;
		// height: 50px;
		font-size: 14px;
		font-weight: 400;
		color: #a5a4a5;
		line-height: 50px;
		background-color: #16275c;
		text-align: center;
		a{
			color: #a5a4a5;
			text-decoration: none;
		}
		a:hover{
			color: #fba919;
			text-decoration: none;
		}

		@media screen and (min-width:768px) {
			display: flex;
			justify-content: center;
		}

		@media screen and (max-width:767px) {
			padding: 10px 0;
			line-height: 30px;
		}
	}
</style>
