<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script >
export default {

}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
</style>
