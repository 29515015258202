<!-- 首页新闻&活动 -->
<template>
	<div>
		<div class="news_content">
			<h2>新闻&活动</h2>
			<p class="n_text">关注天佑德青稞酒，了解企业近期大小事</p>
			<div class="news">
				<div class="news1" v-for="item in toNews" :key="item.id">
					<div class="news_image">
						<router-link :to="'/news/newDetails/' + (item.id)" target="_blank"><img v-lazy="item.thumbnail"
								:alt="item.post_title" /></router-link>
					</div>
					<p class="n_time">{{ item.published_time | subString }}</p>
					<p class="n_title">
						<router-link :to="'/news/newDetails/' + (item.id)" target="_blank">{{ item.post_title }}
						</router-link>
					</p>
					<p class="n_pr"></p>
					<p class="n_textend">{{ item.post_excerpt }}</p>
				</div>
			</div>
			<div class="n_btnAll">
				<button class="n_btn">
					<router-link to="/news/35">了解更多</router-link>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			toNews: Array
		}
	};
</script>

<style lang="less" scoped>
	.news_content {
		width: 1200px;
		margin: 0 auto;

		@media screen and (max-width:1199px) {
			width: 100%;
			padding: 0 4%;
			box-sizing: border-box;
		}
	}

	h2 {
		margin: 72px 0 25px;
		text-align: center;
		font-size: 40px;

		@media screen and (max-width:767px) {
			margin: 50px 0 25px;
			font-size: 30px;
		}
	}

	.n_text {
		text-align: center;
		font-size: 18px;

		@media screen and (max-width:767px) {
			font-size: 16px;
		}
	}

	.news {
		margin: 40px 0 45px;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width:1199px) {
			flex-wrap: wrap;
			margin: 40px 0 0;
		}
	}

	.news1 {
		width: 351px;

		@media screen and (max-width:1199px) {
			width: 100%;
			padding-bottom: 30px;
		}
	}

	.news_image {
		overflow: hidden;
		width: 100%;

		@media screen and (min-width:1200px) {
			height: 217px;
		}

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
		}
	}

	.news_image img {
		width: 100%;
		height: 100%;
		vertical-align: bottom;
		-webkit-transition: opacity .4s ease-in, -webkit-transform .6s linear;
		transition: opacity .4s ease-in, transform .6s linear;
		transform: scale(1);
	}
	
	@media screen and (min-width:1200px) {
		.news_image img:first-child:hover {
			transform: scale(1.1);
		}
	}

	.n_time {
		margin: 10px 0;
		font-family: SourceHanSansCN;
		font-weight: 400;
		font-size: 20px;
	}

	.n_title {
		font-size: 22px;
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media screen and (max-width:767px) {
			font-size: 20px;
		}

		a {
			text-decoration: none;
			color: #000;
		}
	}

	.n_pr {
		width: 100%;
		height: 1px;
		background-color: #fba919;
		margin: 15px 0;

		@media screen and (max-width:991px) {
			margin: 10px 0;
		}
	}

	.n_textend {
		font-size: 18px;
		line-height: 29px;
		font-weight: 400;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		@media screen and (max-width:767px) {
			font-size: 16px;
		}
	}

	.n_btnAll {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 72px;
	}

	.n_btn {
		text-align: center;
		font-size: 30px;
		border: 0;
		// width: 25%;
		// height: 113px;
		background: #FBA919;

		// padding: 0 30px;
		@media screen and (min-width:1200px) {
			width: 300px;
		}

		@media screen and (min-width:992px) and (max-width:1199px) {
			width: 250px;
			font-size: 28px;
		}

		@media screen and (min-width:768px) and (max-width:991px) {
			width: 200px;
			font-size: 26px;
		}

		@media screen and (max-width:767px) {
			width: 150px;
			font-size: 22px;
		}

		a {
			color: #ffffff;
			text-decoration: none;
			display: block;
			width: 100%;
			height: 100%;
			line-height: 113px;

			@media screen and (min-width:992px) and (max-width:1199px) {
				line-height: 105px;
			}

			@media screen and (min-width:768px) and (max-width:991px) {
				line-height: 90px;
			}

			@media screen and (max-width:767px) {
				line-height: 80px;
			}
		}
	}
</style>
