<!-- swiper -->
<template>
  <div class="pku_card">
    <h3 class="pku_cardTit">精彩瞬间</h3>
    <div class="myCardSwiper">
      <div @mouseleave="leave" @mouseenter="enter">
        <swiper
          class="swiper-wrapper swiperOption"
          ref="mySwiper"
          :options="swiperOption"
        >
          <swiper-slide v-for="item in tocardList" :key="item.id">
            <div class="swiper_img">
              <img :src="item.thumbnail" alt="" />
            </div>
            <div class="swiper_title">
              <p>{{ item.post_title }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <swiper
          class="swiper-wrapper iphoneOption"
          ref="mySwiper"
          :options="iphoneOption"
        >
          <swiper-slide v-for="item in tocardList" :key="item.id">
            <div class="swiper_img">
              <img :src="item.thumbnail" alt="" />
            </div>
            <div class="swiper_title">
              <p>{{ item.post_title }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div @mouseenter="enter" @mouseleave="leave">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    tocardList: Array,
  },
  data() {
    return {
      swiperOption: {
        // 衔接滑动
        loop: true,
        // 自动滑动
        autoplay: {
          delay: 3000,
          // 如果设置为true，当切换到最后一个slide时停止自动切换。
          stopOnLastSlide: false,
          // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
          disableOnInteraction: false,
        },
        initialSlide: 1, //默认显示第二张在中心位置
        // 切换效果 "coverflow"（3d流）
        effect: "coverflow",
        // 设置slider容器能够同时显示的slides数量
        slidesPerView: 2,
        // 居中幻灯片。设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
        centeredSlides: true,
        // 设置为true则点击slide会过渡到这个slide。
        slideToClickedSlide: true,
        coverflowEffect: {
          // slide做3d旋转时Y轴的旋转角度
          rotate: 0,
          // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
          stretch: 0,
          // slide的位置深度。值越大z轴距离越远，看起来越小。
          depth: 60,
          // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
          modifier: 6,
          // 是否开启slide阴影
          slideShadows: false,
        },
        // 使用前进后退按钮来控制Swiper切换。
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      iphoneOption: {
        // 衔接滑动
        loop: true,
        // 自动滑动
        autoplay: {
          delay: 2000,
          // 如果设置为true，当切换到最后一个slide时停止自动切换。
          // stopOnLastSlide: false,
          // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
          // disableOnInteraction: false,
        },
        // 使用前进后退按钮来控制Swiper切换。
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    mySwiper() {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    enter() {
      this.mySwiper.autoplay.stop();
    },
    leave() {
      this.mySwiper.autoplay.start();
    },
  },
};
</script>
<style scoped lang="less">
.pku_card {
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 4% 100px;
    box-sizing: border-box;
  }
  @media screen and (min-width: 992px) {
    background: url(@/static/img/pku-brilliantBg.png) no-repeat;
    background-size: 100% 100%;
    padding-bottom: 140px;
  }
}
// 标题
.pku_cardTit {
  width: 1200px;
  margin: 0 auto;
  font-size: 36px;
  font-weight: 400;
  padding: 45px 0 30px;

  @media screen and (max-width: 767px) {
    font-size: 30px;
    font-weight: 500;
  }
}
.swiper_img img {
  width: 100%;
  vertical-align: bottom;
}
.swiper_title {
  display: none;
  text-align: center;
  padding: 25px 0 0;
  p {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.swiper-slide-active .swiper_title {
  display: block;
}
.swiper-slide-active {
  padding-top: 5px;
}

.myCardSwiper {
  position: relative;
  @media screen and (min-width: 1199px) {
    width: 1200px;
    margin: 0 auto;
  }
}
.swiper-wrapper {
  width: 100%;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
}
.swiperOption {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
.iphoneOption {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.swiper-button-prev {
  left: 45%;
  right: auto;
  @media screen and (min-width: 500px) and (max-width: 767px) {
    left: 40%;
  }
  @media screen and (max-width: 499px) {
    left: 38%;
  }
}
.swiper-button-next {
  right: 45%;
  left: auto;
  @media screen and (min-width: 500px) and (max-width: 767px) {
    right: 40%;
  }
  @media screen and (max-width: 499px) {
    right: 38%;
  }
}
.swiper-button-prev,
.swiper-button-next {
  background-color: #fba919;
  color: #fff;
  border-radius: 0;
  width: 35px;
  height: 35px;
  top: auto;
  bottom: -80px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}
.swiper-slide-next img{
  filter:contrast(70%);
}
.swiper-slide-prev img{
  filter:contrast(70%);
}
</style>