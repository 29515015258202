import { render, staticRenderFns } from "./naissance.vue?vue&type=template&id=49c1ae84&scoped=true"
import script from "./naissance.vue?vue&type=script&lang=js"
export * from "./naissance.vue?vue&type=script&lang=js"
import style0 from "./naissance.vue?vue&type=style&index=0&id=49c1ae84&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c1ae84",
  null
  
)

export default component.exports