<template>
    <div class="noNewDetails">
        <div class="noNewDetails_con">
            <p class="noNewDetails_error">404 页面不存在! 您打开的是过期的书签或是输入了错误的地址！</p>
            <p class="noNewDetails_time"><span>{{ initTime }}</span>秒 <router-link to="/">返回首页</router-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            initTime: 5 //倒计时
        }
    },
    created() {
        setInterval(this.callTime, 1000)
    },
    methods: {
        callTime() {
            this.initTime--;
            if (this.initTime == 0) {
                this.$router.push('/');
                location.reload();
            }
        }
    }
}
</script>

<style lang="less" scoped>
.noNewDetails {
    width: 100%;
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;

    .noNewDetails_con {
        text-align: center;

        .noNewDetails_error {
            font-size: 24px;
            margin: 24px 0;
        }
    }
}
</style>