<!-- 天佑德×北大 -->
<template>
  <div id="pku">
    <!-- 导航栏 -->
    <navs />
    <!-- 总内容 -->
    <div class="pku_con">
      <!-- 标题图片 -->
      <div class="pku_top">
        <img src="@/static/img/pku-top.png" alt="" />
      </div>
      <!-- 活动简介 -->
      <div id="pku_play" class="pku_play">
        <div class="pku_playBg">
          <img src="@/static/img/pku-playBg.png" alt="" />
        </div>
        <div class="pku_playCon">
          <h3 class="pku_playTit">活动简介</h3>
          <p class="pku_playText">
            为报答母校知育之恩与青藏高原多年的养育之情，助力我国教育科研工作，特别是青藏地区相关产、学、研工作全面高质量发展，2023年4月17日，北大83级校友、天佑德酒董事长李银会先生，携天佑德酒（股票代码：002646）向母校北京大学捐赠价值1亿元企业股权，成立“北京大学天佑德教育发展基金”（以下简称“本基金”）。本基金款项主要用于：北京大学教学与科研等项目硬件软件建设、北大师资与学子（尤其是青藏学子）教育发展、青藏高原文化全面研学与传播交流高端平台建设等工作的开展。
          </p>
          <p class="pku_playText">
            通过本基金的设立，天佑德将鼎力支持北京大学，以青藏高原自然环境、民俗文化、高原动植物、特色产业及本地教育等为主题，邀请相关领域的知名学者和专家教授，与关心青藏高原发展的朋友们一同，携手搭建青藏高原文化研学与传播交流的重要平台。在此，天佑德诚邀各位，与我们一起深入了解青藏高原的方方面面，为青藏文化的传播和发展助力！
          </p>
        </div>
      </div>
      <!-- 息息相通 -->
      <Linkage :tolinkageList = 'this.linkageList' />
      <!-- 精彩瞬间 -->
      <CardLunBo :tocardList = 'this.cardList'/>
      <!-- 从善如流 -->
      <div class="pku_news">
        <div class="pku_bg"></div>
        <div class="pku_newCon">
          <div class="pku_newTitle">
            <h3>从善如流</h3>
            <p>
              天佑德酒在发展过程中，从未忘记回馈社会，以实际行动持续履行企业社会责任。
            </p>
          </div>
          <ul class="pku_newAll">
            <li class="pku_newLi">
              <div class="pku_newImg">
                <img src="@/static/img/pku-new1.png" alt="" />
              </div>
              <div class="pku_newText">
                <h3>青稞行动</h3>
                <p>
                  本活动致力于关爱西部乡村地区教育事业发展，向青海、甘肃两省诸多学校和学生送去其亟需的教育生活物资。自2015年开展至今，已落地148
                  场校园爱心捐赠活动，行程总计8万多公里，累计捐资千万以上，使上万名学生受益。
                </p>
              </div>
            </li>
            <li class="pku_newLi">
              <div class="pku_newImg">
                <img src="@/static/img/pku-new2.png" alt="" />
              </div>
              <div class="pku_newText">
                <h3>保护三江源</h3>
                <p>
                  为守护我国“中华水塔”——三江源自然保护区，天佑德酒携手世界自然基金会WWF，一同保护中华生态屏障“三江源生态系统”，如：开展高原湿地生态监测&动植物调查（黑颈鹤、斑头雁等水鸟及藏野驴、藏原羚等）、珍稀物种保护及相关宣传工作。
                </p>
              </div>
            </li>
            <li class="pku_newLi">
              <div class="pku_newImg">
                <img src="@/static/img/pku-new3.png" alt="" />
              </div>
              <div class="pku_newText">
                <h3>“袋”动中国——文明旅游在青海</h3>
                <p>
                  为对抗旅游带来的环境污染问题，天佑德酒冠名支持了“袋动中国——文明旅游在青海”活动，足迹遍布青海湖、茶卡盐湖、贵德国家地质公园等青海省内各大景区，举办40余场公益活动。5000多名志愿者，用一个个小小的袋子，唤醒民众的环保意识与文明意识。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <foot />
    <!-- 回到顶部 -->
    <call />
  </div>
</template>

<script>
import Navs from "@/components/index/navs.vue";
import Foot from "@/components/index/foot.vue";
import Call from "@/components/index/BackTop.vue";
// 息息相通
import Linkage from "@/components/pku/linkages.vue";
// 精彩瞬间
import CardLunBo from "@/components/pku/CardLunBo.vue";
export default {
  components: { Navs, Foot, Call, Linkage, CardLunBo },
  data () {
    return {
      cardList:[],
      linkageList:[]  
    }
  },
  created() {
    this.$axios
      .get("/qkj/v1.beida/index")
      .then((response) => {
        // console.log(response.data.data.data);
        this.cardList = response.data.data.data.article
        this.linkageList = response.data.data.data.article_list
      })
      // 错误处理
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    // 设置项目title
    document.title = "天佑德×北大_天佑德青稞酒官网";
    this.goPlay()
  },
  methods: {
    goPlay() {
				//查找存储的锚点id
				let Id = localStorage.getItem('toPku');
				setTimeout(() => {
					this.$nextTick(() => {
						if (Id) {
							var top = document.getElementById(Id).offsetTop
							window.scrollTo({
								top: top - 96
							});
						}
					})
				}, 200)
			},
  }
};
</script>

<style lang="less" scoped>
#pku {
  margin-top: 96px;
}
// 天佑德x北大 内容
.pku_con {
  width: 100%;
}
// 标题图片
.pku_top {
  width: 100%;
  img {
    width: 100%;
    vertical-align: bottom;
  }
}
// 活动简介
.pku_play {
  width: 100%;
  height: 100%;
  position: relative;
  @media screen and (max-width: 991px) {
    padding-bottom: 50px;
    background-color: rgb(239, 239, 239);
  }
  .pku_playBg {
    @media screen and (max-width: 991px) {
      display: none;
    }
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }

  .pku_playCon {
    width: 1200px;
    margin: 0 auto;

    @media screen and (min-width: 992px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    @media screen and (max-width: 1199px) {
      width: 100%;
      padding: 0 4%;
      box-sizing: border-box;
    }
    .pku_playTit {
      padding: 45px 0 30px;
      font-size: 36px;
      font-weight: 400;
      @media screen and (max-width: 767px) {
        font-size: 30px;
        font-weight: 500;
      }
    }
    .pku_playText {
      text-indent: 2em;
      letter-spacing: 1px;
      line-height: 36px;
    }
  }
}
// 从善如流
.pku_news {
  position: relative;
  padding: 45px 0 72px;

  @media screen and (max-width: 1199px) {
    padding: 50px 0 30px;
  }
}

.pku_bg {
  background-color: #e1e1e1;
  width: 100%;
  height: 307px;
  position: absolute;
  bottom: 0;
  z-index: -1;

  @media screen and (max-width: 1199px) {
    height: 100%;
  }
}

.pku_newCon {
  width: 1200px;
  margin: 0 auto;
  z-index: 999;

  @media screen and (max-width: 1199px) {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
  }
}

.pku_newTitle {
  h3 {
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      font-size: 30px;
      font-weight: 500;
    }
  }

  p {
    font-size: 18px;
    margin: 24px 0 48px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      margin: 25px 0 40px;
    }
  }
}

.pku_newAll {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pku_newLi {
    width: 32%;
    background-color: #fff;

    @media screen and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 30px;
    }

    .pku_newImg {
      width: 100%;
      position: relative;
      overflow: hidden;

      img:first-child {
        -webkit-transition: opacity 0.4s ease-in, -webkit-transform 0.6s linear;
        transition: opacity 0.4s ease-in, transform 0.6s linear;
        transform: scale(1);
      }

      @media screen and (min-width: 1200px) {
        img:first-child:hover {
          transform: scale(1.1);
        }
      }

      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }
}

.pku_newText {
  box-sizing: border-box;
  padding: 0 24px 25px;

  @media screen and (max-width: 1199px) {
    padding: 0 24px 20px;
  }

  h3 {
    font-size: 18px;
    line-height: 54px;
  }
  p {
    line-height: 26px;
  }
}
</style>